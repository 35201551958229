<template>
  <div class="butterfly">
    <div class="grid-container home-top">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 text-center">
          <div class="lozenge">We're here to</div>
          <div class="transform-text">transform</div>
          <div class="lives-text">lives</div>
          <div class="wall-text">
            <router-link to="/wall">
              Virtual WALL
            </router-link>
          </div>
          <div>
            <p>
              <strong>Every role</strong> in Amicus has a part to play in transforming lives.<br />
              <strong>Click on any picture</strong> on the wall and discover who was born to
              transform lives by working as part of our team.
            </p>
          </div>
          <div class="continue-button">
            <router-link to="/wall">
              <img src="../assets/continue_button.png" alt="Continue" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style lang="scss" scoped>
  .home-top {
    padding-top: 140px;
  }
  .lozenge {
    display: inline-block;
    background-image: linear-gradient(to right, #243171 , #00b0bc);
    padding: 0 20px;
    border-radius: 20px;
    color: #FFF;
    font-family: 'co_headlinebold';
    text-transform: uppercase;
    font-size: 18px;
    height: 30px;
    line-height: 28px;
    letter-spacing: 3px;
  }
  .transform-text {
    font-family: 'co_headlinebold';
    font-size: 70px;
    color: #ed8423;
    line-height: 0.9;
  }
  .lives-text {
    font-family: 'co_headlinebold';
    font-size: 70px;
    color: #ad1f25;
    line-height: 0.9;
  }
  .wall-text {
    padding-top: 40px;
    padding-bottom: 100px;
    font-family: 'co_headlinebold';
    font-size: 100px;
    color: #243171;
    background: -webkit-linear-gradient(left, #006094 , #00b2bd);
    background: -o-linear-gradient(right, #006094, #00b2bd);
    background: -moz-linear-gradient(right, #006094, #00b2bd);
    background: linear-gradient(to right, #006094, #00b2bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1;
  }
  .continue-button {
    text-align: center;
    padding-bottom: 80px;
    img {
      width: 300px;
    }
  }
  .butterfly {
    background-image: url('../assets/butterfly.png');
    background-repeat: no-repeat;
    background-position: top -20px right -60px;
    background-size: 140% auto;
  }

@media all and (min-width: 500px) {
  .butterfly {
    background-size: 120%;
    background-position: top -40px right -80px;
  }
}

@media all and (min-width: 750px) {
  .butterfly {
    background-size: 100%;
    background-position: top -60px right -100px;
  }
}

@media all and (min-width: 1000px) {
  .butterfly {
    background-size: 90%;
    background-position: top -60px right -140px;
  }
}
</style>
