<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
// Fonts
@font-face {
  font-family: 'honey_scriptlight';
  src: url('./assets/fonts/honeyscript-light-webfont.woff2') format('woff2'),
    url('./assets/fonts/honeyscript-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'honey_scriptsemibold';
  src: url('./assets/fonts/honeyscript-semibold-webfont.woff2') format('woff2'),
    url('./assets/fonts/honeyscript-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'co_headlinebold';
  src: url('./assets/fonts/coheadline-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/coheadline-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'co_headlinelight';
  src: url('./assets/fonts/coheadline-light-webfont.woff2') format('woff2'),
    url('./assets/fonts/coheadline-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'co_headlineregular';
  src: url('./assets/fonts/coheadline-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/coheadline-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Typography
h1,h2,h3,h4,p {
  font-family:
    "HelveticaNeue-Light",
    "Helvetica Neue Light",
    "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
}

p {
  font-size: 18px;
}

// Padding
.pa-10 {
  padding: 20px;
}
.pt-10 {
  padding-top: 20px;
}
.pt-15 {
  padding-top: 30px;
}
.pt-20 {
  padding-top: 40px;
}
.pt-40 {
  padding-top: 80px;
}
.pt-60 {
  padding-top: 100px;
}
.pb-10 {
  padding-bottom: 20px;
}
.pb-15 {
  padding-bottom: 30px;
}
.pb-20 {
  padding-bottom: 40px;
}
</style>
