import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/wall',
    name: 'Wall',
    component: () => import('../views/Wall.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import('../views/Start.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/add-member',
    name: 'AddMember',
    component: () => import('../views/AddMember.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
];

const router = new VueRouter({
  // mode: 'history',
  routes,
});

export default router;
